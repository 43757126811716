import React from "react";
import { createUseStyles } from "react-jss";
import PropType from "prop-types";

const useStyle = createUseStyles((theme) => {
  let style = {
    base: {
      margin: (props) => props.margin,
      marginTop: (props) => props.marginTop,
      marginBottom: (props) => props.marginBottom,
      marginLeft: (props) => props.marginLeft,
      marginRight: (props) => props.marginRight,
      padding: (props) => props.padding,
      flexDirection: (props) => props.direction,
      borderRadius: (props) => props.radius,
      background: (props) => props.background,
      border: (props) => props.border,
      maxWidth: (props) => props.maxWidth,
      width: (props) => props.width,
      height: (props) => props.height
    },
    default: {
      display: "flex",
      flexWrap: "wrap",
      boxSizing: "border-box",
    },
    gridComponent: {
      display: "flex",
      flexWrap: "wrap",
      boxSizing: "border-box",
      justifyContent: (props) =>
        props.justify ? props.justify : theme.grid.justify,
      alignItems: (props) => (props.align ? props.align : theme.grid.align),
    },
    gridItem: {
      flexGrow: 0,
      maxWidth: "100%",
      flexBasis: "100%",
      justifyContent: (props) =>
        props.justify ? props.justify : theme.grid.justify,
      alignItems: (props) => (props.align ? props.align : theme.grid.align),
    },
    containerSpacing: {
      gap: (props) => props.spacing * theme.spacing,
    },
  };

  Object.keys(theme.breakpoints).forEach((item) => {
    const types = {};
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].forEach((col) => {
      style[`gridItem-${item}-${col}`] = {};
      types[`gridItem-${item}-${col}`] = {
        width: `${(col / 12) * 100}%`,
        flexBasis: `${(col / 12) * 100}%`,
      };
    });

    style[`@media (min-width: ${theme.breakpoints[item]}px)`] = types;
  });
  return style;
});

/**
 * Grid Component to integrate the 12 part grid in the platform.
 *
 * 1. container: each container should be defined before using item on grid item.
 * 2. item: each grid item should be definde with the breaking point.
 * 3. spacing: spacing should be 1 - infinity so that multiple value is spacing * 2 ( 2 is default)
 * 4. align | justify are normal style properties.
 *
 */
const Grid = ({
  container,
  item,
  spacing,
  justify,
  align,
  xs = false,
  sm = false,
  md = false,
  lg = false,
  xl = false,
  className,
  children,
  border,
  direction,
  maxWidth,
  width,
  height,
  style,
  ...other
}) => {
  const styleProps = {
    container,
    item,
    spacing,
    justify,
    align,
    border,
    maxWidth,
    width,
    height,
    direction,
    breakpoints: { xs, sm, md, lg, xl },
    ...other,
  };

  const classes = useStyle(styleProps);

  /**
   * Generate classes based on the provided props.
   * @returns string of the class name seperated by " "
   */
  function getClasses() {
    const arr = [];
    arr.push(className);
    arr.push(classes.base);

    container && arr.push(classes.gridComponent);
    item && arr.push(classes.gridItem);
    container && item && arr.push(classes.default);
    spacing && arr.push(classes.containerSpacing);

    Object.keys(styleProps.breakpoints).forEach((item) => {
      const value = styleProps.breakpoints[item];
      value && arr.push(classes[`gridItem-${item}-${value}`]);
    });
    return arr.join(" ");
  }

  return (
    <div className={getClasses()} style={{ ...style }} {...other}>
      {children}
    </div>
  );
};

/**
 * TODO: add the typescript support to make this use full
 */
Grid.propTypes = {
  children: PropType.node,
  container: PropType.bool,
  item: PropType.bool,
  justify: PropType.oneOf([
    "space-between",
    "center",
    "space-around",
    "flex-start",
    "space-evenly",
  ]),
  align: PropType.oneOf([
    "space-between",
    "center",
    "space-around",
    "flex-start",
    "space-evenly",
  ]),
  xs: PropType.number,
  sm: PropType.number,
  md: PropType.number,
  lg: PropType.number,
  xl: PropType.number,
  spacing: PropType.number,
  className: PropType.string,
};

export default Grid;
