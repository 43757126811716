import React from "react";
import { useStyles } from "../styles/AvatarDescription";

const OnClickElement = ({ ...props }) => {
  const classes = useStyles(props);
  return (
    <div
      id="tooltip-label"
      className={classes.tooltipLabel}
      style={
        props.position === "top"
          ? { top: "0px", transform: "translateY(calc(-100% - 6px))" }
          : { bottom: "0px", transform: "translateY(calc(100% + 6px))" }
      }
    >
      <div style={{ padding: "1rem" }}>
        <div
          className={
            props.position === "top"
              ? classes.tooltipLabelMarkerTop
              : classes.tooltipLabelMarkerBottom
          }
        ></div>
        <p>{props.onClickText}</p>
      </div>
      <div
        className={
          props.position === "top"
            ? classes.tooltipDownArrow
            : classes.tooltipUpArrow
        }
      />
    </div>
  );
};

export default OnClickElement;
