import React from "react";
import loadScript from "load-script";
import PropTypes from "prop-types";

const GOOGLE_SDK_URL = "https://apis.google.com/js/api.js";
const GSI_API = "https://accounts.google.com/gsi/client";

let scriptLoadingStarted = false;
let client = null;
let access_token = null;

class GooglePicker extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    clientId: PropTypes.string.isRequired,
    developerKey: PropTypes.string,
    scope: PropTypes.array,
    viewId: PropTypes.string,
    authImmediate: PropTypes.bool,
    origin: PropTypes.string,
    onChange: PropTypes.func,
    onAuthenticate: PropTypes.func,
    onAuthFailed: PropTypes.func,
    createPicker: PropTypes.func,
    multiselect: PropTypes.bool,
    navHidden: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    onChange: () => {},
    onAuthenticate: () => {},
    onAuthFailed: () => {},
    scope: ["https://www.googleapis.com/auth/drive.readonly"],
    viewId: "DOCS",
    authImmediate: false,
    multiselect: false,
    navHidden: false,
    disabled: false,
  };

  constructor(props) {
    super(props);
    if (props.authToken) {
      access_token = props.authToken;
    }
    this.initClient = this.initClient.bind(this);
    this.onApiLoad = this.onApiLoad.bind(this);
    this.getToken = this.getToken.bind(this);
  }

  componentDidMount() {
    if (!scriptLoadingStarted) {
      // load google api and the init
      scriptLoadingStarted = true;
      loadScript(GSI_API, this.initClient);
      loadScript(GOOGLE_SDK_URL, this.onApiLoad);
    }
  }

  isGoogleAuthReady() {
    return !!client;
  }
  isGooglePickerReady() {
    return !!window.google.picker;
  }

  onApiLoad() {
    scriptLoadingStarted = false;
    window.gapi.load("picker");
  }

  async getPicker() {
    if (
      !this.isGooglePickerReady() ||
      !this.isGoogleAuthReady() ||
      this.props.disabled
    ) {
      return null;
    }

    if (access_token) {
      this.createPicker(access_token);
    }
  }

  initClient() {
    client = window.google.accounts.oauth2.initTokenClient({
      client_id: this.props.clientId,
      scope: this.props.scope.join(" "),
      callback: (response) => {
        if (response.access_token) {
          access_token = response.access_token;
          this.getPicker();
        } else {
          this.props.onAuthFailed && this.props.onAuthFailed(response);
        }
      },
    });
  }

  async getToken() {
    if (access_token) {
      this.getPicker();
    } else {
      await client.requestAccessToken();
    }
  }

  revokeToken() {
    window.google.accounts.oauth2.revoke(access_token, () => {
      console.log("access token revoked");
    });
  }

  createPicker(oauthToken) {
    this.props.onAuthenticate(oauthToken);

    if (this.props.createPicker) {
      return this.props.createPicker(window.google, oauthToken);
    }

    const googleViewId = window.google.picker.ViewId[this.props.viewId];
    const view = new window.google.picker.View(googleViewId);

    if (this.props.mimeTypes) {
      view.setMimeTypes(this.props.mimeTypes.join(","));
    }
    if (this.props.query) {
      view.setQuery(this.props.query);
    }

    if (!view) {
      throw new Error("Can't find view by viewId");
    }
    const picker = new window.google.picker.PickerBuilder()
      .addView(view)
      .setOAuthToken(oauthToken)
      .setDeveloperKey(this.props.developerKey)
      .setCallback(this.props.onChange);

    if (this.props.origin) {
      picker.setOrigin(this.props.origin);
    }

    if (this.props.navHidden) {
      picker.enableFeature(window.google.picker.Feature.NAV_HIDDEN);
    }

    if (this.props.multiselect) {
      picker.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED);
    }

    picker.build().setVisible(true);
  }

  render() {
    return (
      <div onClick={this.getToken}>
        {this.props.children && this.props.children}
        {!this.props.children && <button>Drive Picker</button>}
      </div>
    );
  }
}

export default GooglePicker;
