export const themeColors = [
  {
    color: "#7C98B6",
    backgroundColor: "#CFE0ED",
  },
  {
    color: "#6A78D1",
    backgroundColor: "#CDD2FB",
  },
  {
    color: "#00BDA5",
    backgroundColor: "#A9F3EC",
  },
  {
    color: "#3B9CFE",
    backgroundColor: "#AFE3FB",
  },
  {
    color: "#F5C26B",
    backgroundColor: "#FFEED2",
  },
  {
    color: "#F2547D",
    backgroundColor: "#FCDFE8",
  },
  {
    color: "#FF7A59",
    backgroundColor: "#FCE8E4",
  },
  {
    color: "#F2545B",
    backgroundColor: "#F9E0E1",
  },
  {
    color: "#00D0FB",
    backgroundColor: "#C9F4FD",
  },
  {
    color: "#7998B9",
    backgroundColor: "#2D3E50",
  },
];

export function getTheme(name, theme) {
  if (!name || theme === "none") return themeColors[0];
  const firstLetter = name.slice(0, 1).toUpperCase();
  const index = firstLetter.charCodeAt(0) - 65;
  const num = Math.floor(index / (26 / 9));
  return themeColors[num + 1];
}
