import React from "react";
import AvatarDescription from "./components/AvatarDescription";
import { getTheme } from "./themeColors";
import { useAvatarStyles } from "./styles/AvatarStyles";

const Avatar = ({
  children,
  theme = "default",
  isTooltip = false,
  name,
  noStatusCircleOffset,
  ...props
}) => {
  const colorTheme = getTheme(name ? name.trim() : "", theme);
  const classes = useAvatarStyles({ colorTheme, noStatusCircleOffset, ...props });
  return (
    <AvatarDescription
      name={name}
      email={props.email}
      themeColor={colorTheme}
      isDisabled={!isTooltip}
      position={props.tooltipPosition}
      statusElement={props.statusElement}
      roleElement={props.roleElement}
      avatarClasses={classes}
      colorTheme={colorTheme}
      {...props}
    >
      <div
        className={`${classes.avatarContainer} ${classes.avatarRegularContainer}`}
      >
        {props.imageSource ? (
          <img
            className={classes.avatarImage}
            src={props.imageSource}
            alt="PK"
          />
        ) : (
          name && (
            <div
              className={`${classes.noAvatarIcon} ${classes.noAvatarIconRegular}`}
            >
              {name.trim().slice(0, 1).toUpperCase()}
            </div>
          )
        )}
        {props.statusDotVisible &&
        (props.status !== "none" || props.statusHighlightColor) ? (
          <div className={classes.avatarStatusDot} />
        ) : null}
        {children ? <p className={classes.avatarText}>{children}</p> : null}
      </div>
    </AvatarDescription>
  );
};

Avatar.defaultProps = {
  bgColor: "transparent",
  border: "none",
  imageSource: null,
  size: "m",
  shape: "round",
  status: "none",
  fontSize: "16",
  fontWeight: "500",
  statusHighlightColor: "",
  name: "",
  statusDotVisible: true,
};

export default Avatar;
