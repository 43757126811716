import React, { useState, useEffect, memo } from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  tooltipContainer: {
    position: "relative",
    height: "max-content",
    width: "max-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&:hover #tooltip-label": {
      display: "flex",
    },
  },
  tooltipLabel: {
    display: "none",
    position: "absolute",
    height: 20,
    width: "max-content",
    padding: "7px 15px",
    backgroundColor: "#425B76",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontSize: 14,
    fontWeight: 400,
    borderRadius: 3,
    zIndex: 100,
  },
  tooltipDownArrow: {
    position: "absolute",
    bottom: -6,
    width: 0,
    height: 0,
    borderLeft: "6px solid transparent",
    borderRight: "6px solid transparent",
    borderTop: "6px solid #425B76",
  },
  tooltipUpArrow: {
    position: "absolute",
    top: -6,
    width: 0,
    height: 0,
    borderLeft: "6px solid transparent",
    borderRight: "6px solid transparent",
    borderBottom: "6px solid #425B76",
  },
});

const Tooltip = memo(({ children, offset, ...props }) => {
  const [isClicked, setIsClicked] = useState(false);
  const classes = useStyles();
  if(!offset) offset = -43

  useEffect(() => {
    if (isClicked) {
      setTimeout(() => {
        setIsClicked(false);
      }, 2000);
    }
  }, [isClicked, setIsClicked]);

  const handleClick = () => {
    if (props.onClickText) {
      // If there is no text, so it doesn't make sense to render
      setIsClicked(true);
    }
  };
  return (
    <div
      className={classes.tooltipContainer}
      onClick={() => handleClick()}
      {...props}
    >
      {isClicked ? (
        <div
          className={classes.tooltipLabel}
          style={
            props.position === "top"
              ? { top: offset, display: "flex" }
              : { bottom: offset, display: "flex" }
          }
        >
          <p>{props.onClickText}</p>
          <div
            className={
              props.position === "top"
                ? classes.tooltipDownArrow
                : classes.tooltipUpArrow
            }
          />
        </div>
      ) : (
        <div
          id="tooltip-label"
          className={classes.tooltipLabel}
          style={
            props.position === "top" ? { top: offset } : { bottom: offset }
          }
        >
          <p>{props.onHoverText}</p>
          <div
            className={
              props.position === "top"
                ? classes.tooltipDownArrow
                : classes.tooltipUpArrow
            }
          />
        </div>
      )}
      {children}
    </div>
  );
});

Tooltip.defaultProps = {
  position: "top",
  onHoverText: "Copy to Clipboard",
  onClickText: null,
};

export default Tooltip;
