import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  tooltipContainer: {
    position: "relative",
    height: "max-content",
    width: "max-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&:hover #tooltip-label": {
      display: "flex",
    },
  },
  tooltipLabel: {
    display: "none",
    position: "absolute",
    width: "max-content",
    backgroundColor: "#ffffff",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    fontWeight: 400,
    zIndex: 100,
    boxShadow: "0 0 18px #99ACC2",
    flexDirection: "column",
    maxWidth: 300,
    borderRadius: 11,
  },
  tooltipLabelMarkerTop: (props) => ({
    position: "absolute",
    bottom: -10,
    width: "100%",
    height: "10px",
    backgroundColor: props.themeColor.color,
    transform: "translateY(-10px)",
    left: 0,
    borderRadius: "0 0 11px 11px",
  }),
  tooltipLabelMarkerBottom: (props) => ({
    position: "absolute",
    top: -10,
    left: 0,
    width: "100%",
    height: "10px",
    backgroundColor: props.themeColor.color,
    transform: "translateY(10px)",
    borderRadius: "11px 11px 0 0",
  }),
  tooltipDownArrow: (props) => ({
    position: "absolute",
    bottom: -6,
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderTop: `6px solid ${props.themeColor.color}`,
  }),
  tooltipUpArrow: (props) => ({
    position: "absolute",
    top: -6,
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: `6px solid ${props.themeColor.color}`,
  }),
  tooltipDataContainer: {
    minWidth: 100,
    minHeight: 70,
  },
  tooltipDataContainerDown: {
    borderTop: (props) => `6px solid ${props.themeColor.color}`,
    borderRadius: "11px 11px 0 0",
  },
  tooltipDataContainerUp: {
    borderBottom: (props) => `6px solid ${props.themeColor.color}`,
    borderRadius: "0 0 11px 11px",
  },
  tooltipDataWrapper: {
    backgroundColor: "#fff",
    borderRadius: 16,
    margin: "1rem",
  },
  tooltipDataBasicWrapper: {
    display: "flex",
    maxWidth: 300,
  },
  tooltipDataBasicWrapperFullCard: {
    display: "block",
  },
  tooltipDetailsWrapper: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "0.75rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  tooltipDetailsEmailWrapper: {
    display: "flex",
    alignItems: "center",
    "& p": {
      marginLeft: "0.5rem",
    },
  },
  statusElement: {
    display: "flex",
    justifyContent: "center",
  },
});
