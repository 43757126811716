import React from "react";
import Text from "../../Text/Text";
import { ReactComponent as EmailIcon } from "../assets/email-icon.svg";
import { useStyles } from "../styles/AvatarDescription";
import { useAvatarStyles } from "../styles/AvatarStyles";

const NormalCard = ({ colorTheme, showBigCard, fullCard, ...props }) => {
  const classes = useStyles(props);
  const avatarClasses = useAvatarStyles({
    colorTheme,
    outlineOffset: 0,
    ...props,
  });
  if (!showBigCard) return <></>;
  return (
    <div
      id="tooltip-label"
      className={classes.tooltipLabel}
      style={
        props.position === "top"
          ? { top: "0px", transform: "translateY(calc(-100% - 6px))" }
          : { bottom: "0px", transform: "translateY(calc(100% + 6px))" }
      }
    >
      <div
        className={`${classes.tooltipDataContainer} ${
          props.position === "top"
            ? classes.tooltipDataContainerUp
            : classes.tooltipDataContainerDown
        }`}
      >
        <div className={classes.tooltipDataWrapper}>
          <div
            className={`${classes.tooltipDataBasicWrapper} ${
              fullCard ? classes.tooltipDataBasicWrapperFullCard : ""
            }`}
          >
            <div>
              <div
                className={`${avatarClasses.avatarContainer} ${
                  fullCard
                    ? avatarClasses.avatarFullCardContainer
                    : avatarClasses.avatarRegularContainer
                }`}
                style={{ outlineOffset: 0, outlineWidth: fullCard ? 0 : 2 }}
              >
                {props.imageSource ? (
                  <img
                    className={`${avatarClasses.avatarImage}`}
                    src={props.imageSource}
                    alt="PK"
                  />
                ) : (
                  props.name && (
                    <div
                      className={`${avatarClasses.noAvatarIcon} ${
                        fullCard
                          ? avatarClasses.noAvatarIconLarge
                          : avatarClasses.noAvatarIconRegular
                      }`}
                    >
                      {props.name.trim().slice(0, 1).toUpperCase()}
                    </div>
                  )
                )}
                {!fullCard &&
                (props.status !== "none" || props.statusHighlightColor) ? (
                  <div
                    className={avatarClasses.avatarStatusDot}
                    style={{
                      bottom: -2.5,
                      right: -2.5,
                    }}
                  />
                ) : null}
              </div>
            </div>
            <div
              className={classes.tooltipDetailsWrapper}
              style={{ alignItems: fullCard ? "center" : "flex-start" }}
            >
              <Text fontSize={16} weight={600} align="center">
                {props.name || "No Username"}
              </Text>
              {props.email && (
                <div className={classes.tooltipDetailsEmailWrapper}>
                  <EmailIcon />
                  <Text
                    varient={"subText"}
                    fontSize={13}
                    color={"light"}
                    align="center"
                  >
                    {props.email.trim().length < 15
                      ? props.email.trim()
                      : props.email.trim().slice(0, 15) + "..."}
                  </Text>
                </div>
              )}
              <div className={classes.statusElement}>
                {props.statusElement && props.statusElement}
              </div>
              <div className={classes.statusElement}>
                {props.roleElement && props.roleElement}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          props.position === "top"
            ? classes.tooltipDownArrow
            : classes.tooltipUpArrow
        }
      />
    </div>
  );
};

export default NormalCard;
