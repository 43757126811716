import { createUseStyles } from "react-jss";

const borderRadiusValue = (shape) => {
  if (shape === "round") {
    return 50;
  } else {
    return 10;
  }
};

const dimensionValue = (size) => {
  switch (size) {
    case "xxs":
      return 20;
    case "xs":
      return 30;
    case "xl":
      return 50;
    case "xxl":
      return 60;
    default:
      return 40;
  }
};

const statusValue = (status) => {
  switch (status) {
    case "inactive":
      return "#C9C9C9";
    case "active":
      return "#2FD875";
    case "busy":
      return "#F3666C";
    default:
      return "transparent";
  }
};

const inactiveOpacity = (status) => {
  if (status === "inactive") {
    return 0.5;
  } else {
    return 1;
  }
};

const borderValue = (border) => {
  if (border !== "none") {
    return `3px solid ${border}`;
  } else {
    return "none";
  }
};

export const useAvatarStyles = createUseStyles({
  avatarContainer: (props) => ({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: borderValue(props.border),
    outline: `2px solid ${
      props.statusHighlightColor || statusValue(props.status)
    }`,
    outlineOffset: props.noStatusCircleOffset ? 0 : 3,
    outlineWidth: 2,
    borderRadius: borderRadiusValue(props.shape),
    backgroundColor: props.bgColor,
    cursor: "pointer",
  }),
  avatarRegularContainer: (props) => ({
    height: dimensionValue(props.size),
    width: dimensionValue(props.size),
  }),
  avatarFullCardContainer: {
    height: 87,
    width: 87,
    margin: "auto",
    marginBottom: "1rem",
  },
  avatarImage: (props) => ({
    height: "100%",
    width: "100%",
    opacity: inactiveOpacity(props.status),
    borderRadius: borderRadiusValue(props.shape),
  }),
  avatarStatusDot: (props) => ({
    position: "absolute",
    bottom: props.noStatusCircleOffset ? -2.5 : -5,
    right: props.noStatusCircleOffset ? -2.5 : -5,
    height: Math.max(10, dimensionValue(props.size) / 3.5),
    width: Math.max(10, dimensionValue(props.size) / 3.5),
    border: "2px solid white",
    borderRadius: 50,
    backgroundColor: props.statusHighlightColor || statusValue(props.status),
  }),
  avatarText: (props) => ({
    fontSize: 15,
    fontWeight: 500,
  }),
  noAvatarIcon: (props) => ({
    color: props.textColor || props.colorTheme.color,
    background:
      props.bgColor !== "transparent"
        ? props.bgColor
        : props.colorTheme.backgroundColor,
    borderRadius: props.shape ? borderRadiusValue(props.shape) : "50%",
    fontWeight: props.fontWeight || "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    userSelect: "none",
  }),
  noAvatarIconRegular: (props) => ({
    height: dimensionValue(props.size),
    width: dimensionValue(props.size),
    fontSize: `${props.fontSize}px` || 15,
  }),
  noAvatarIconLarge: {
    height: 87,
    width: 87,
    fontSize: 33,
  },
});
