import React from "react";
import { createUseStyles } from "react-jss";
import PropType from "prop-types";

const useStyle = createUseStyles((theme) => ({
  btnBase: {
    outline: "none",
    border: "none",
    padding: "12px 20px",
    cursor: "pointer",
    borderRadius: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      opacity: 0.7,
    },
  },
  btnSmall: {
    padding: "8px 12px",
  },
  btnMedium: {
    padding: "12px 24px",
  },
  btnLarge: {
    padding: "16px 26px",
    fontSize: "1.1rem",
  },
  outline: {
    border: (props) =>
      `2px solid ${
        theme.colors[props.color] ? theme.colors[props.color] : props.color
      }`,
    color: (props) => theme.colors[props.color] ? theme.colors[props.color] : props.color,
    background: "transparent",
    "&:hover": {
      opacity: 0.7,
      background: (props) => `${theme.colors[props.color]}20`,
    },
  },
  default: {
    background: (props) => theme.colors[props.color] ? theme.colors[props.color] : props.color,
    color: "#fff",
  },
  round: {
    borderRadius: 20,
  },
  useBtn: {
    padding: (props) => props.padding,
    width: (props) => props.width,
    height: (props) => props.height,
    fontSize: (props) => props.fontSize,
    radius: (props) => props.radius,
    "&:disabled": {
      background: theme.colors["gray-4"],
      color: theme.colors["gray-1"],
    },
  },
  loader: {
    border: `1.5px solid ${theme.colors["gray-3"]}`,
    borderRight: `1.5px solid ${theme.colors["gray-1"]}`,
    borderRadius: "50%",
    minWidth: 10,
    minHeight: 10,
    animation: "1s $spin infinite",
  },
  "@keyframes spin": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
  },
}));

/**
 * Button component with different sizes and other features.
 * it have in build disable feature and loader.
 * @param {*} param0
 * @returns
 */
const Button = ({
  color = "sky",
  size,
  width,
  height,
  loading,
  varient = "default",
  radius,
  round,
  padding,
  disabled,
  children,
  fontSize,
  onClick,
  className,
  style,
  ...others
}) => {
  const classes = useStyle({
    color,
    width,
    height,
    padding,
    radius,
    ...others,
  });

  function getClasses() {
    const arr = [];
    arr.push(classes.btnBase);
    arr.push(classes.useBtn);

    arr.push(classes[varient]);
    if (size === "small") arr.push(classes.btnSmall);
    if (size === "medium") arr.push(classes.btnMedium);
    if (size === "large") arr.push(classes.btnLarge);
    if (round) arr.push(classes.round);
    if (className) arr.push(className);

    return arr.join(" ");
  }

  return (
    <button
      className={getClasses()}
      disabled={disabled}
      onClick={!disabled ? onClick : () => {}}
      style={{ ...style }}
    >
      <span>{children}</span>
      {loading && (
        <>
          &nbsp; <span className={classes.loader}></span>
        </>
      )}
    </button>
  );
};

/**
 * TODO: add the typescript support to make this use full
 */
Button.propTypes = {
  children: PropType.node,
  className: PropType.string,
  color: PropType.oneOf([
    "green",
    "greenDark",
    "sky",
    "red",
    "purple",
    "gray-1",
    "gray-2",
    "gray-3",
    "gray-4",
  ]),
  size: PropType.oneOf(["large", "small", "medium"]),
  width: PropType.number,
  height: PropType.number,
  loading: PropType.bool,
  varient: PropType.oneOf(["default", "outline"]),
  radius: PropType.number,
  round: PropType.bool,
  padding: PropType.number,
  disabled: PropType.bool,
  fontSize: PropType.number,
  onClick: PropType.func,
};

export default Button;
