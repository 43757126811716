import React, { useState, useEffect, memo } from "react";
import ToolTip from "../../Tooltip";
import OnClickElement from "./OnClickElement";
import NormalCard from "./NormalCard";
import { useStyles } from "../styles/AvatarDescription";

const AvatarDescription = memo(({ children, ...props }) => {
  const [isClicked, setIsClicked] = useState(false);
  const classes = useStyles(props);

  const [bigCard, setBigCard] = useState(false);
  let timeout = null;

  const showFullHandler = () => {
    timeout = setTimeout(() => setBigCard(true), 1000);
  };
  const hideFullHandler = () => {
    clearTimeout(timeout);
    setBigCard(false);
  };

  useEffect(() => {
    if (isClicked) {
      setTimeout(() => {
        setIsClicked(false);
      }, 2000);
    }
  }, [isClicked, setIsClicked]);

  const handleClick = () => {
    if (props.onClickText) {
      // If there is no text, so it doesn't make sense to render
      setIsClicked(true);
    }
  };
  if (props.isDisabled) return <>{children}</>;

  if (!bigCard)
    return (
      <ToolTip
        onClick={() => handleClick()}
        onMouseOver={showFullHandler}
        onMouseOut={hideFullHandler}
        offset={-25}
        onHoverText={
          props.name ? props.name : ""
        }
        position="bottom"
      >
        {children}
      </ToolTip>
    );

  return (
    <div
      className={classes.tooltipContainer}
      onClick={() => handleClick()}
      onMouseOver={showFullHandler}
      onMouseOut={hideFullHandler}
    >
      {isClicked ? (
        <OnClickElement classes={classes} {...props} />
      ) : (
        <NormalCard
          classes={classes}
          colorTheme={props.colorTheme}
          avatarClasses={classes.avatarClasses}
          showBigCard={bigCard}
          fullCard={props.fullCard}
          {...props}
        />
      )}
      {children}
    </div>
  );
});

AvatarDescription.defaultProps = {
  position: "bottom",
  onHoverText: "Copy to Clipboard",
  onClickText: null,
};

export default AvatarDescription;
